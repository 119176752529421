import { Box, useColorModeValue } from "@chakra-ui/react";
import React from "react";

export const Embed = ({ children }: any) => {
  const boxValue = useColorModeValue("gray.200", "whiteAlpha.200");

  return (
    <Box
      border="1px solid"
      borderColor="blackAlpha.200"
      overflow="hidden"
      bg={boxValue}
      borderRadius="lg"
    >
      {children}
    </Box>
  );
};
