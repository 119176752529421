import * as React from "react";
import Link from "gatsby-link";
import Helmet from "react-helmet";

import { ChakraProvider, ColorModeScript, Container } from "@chakra-ui/react";
import { Header } from "../components/Header";
import theme from "../theme";
import { Footer } from "../components/Footer";

const DefaultLayout = ({ children }: any) => {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Helmet
        title="Natify | Websockets made easy"
        meta={[
          {
            name: "description",
            content: "Create a dynamic website with a click of a button.",
          },
          { name: "keywords", content: "notifications, websockets, notify" },
        ]}
      />
      <Container maxW={[undefined, null, "container.lg"]} overflowX="hidden">
        <Header />
        {children}
        <Footer />
      </Container>
    </ChakraProvider>
  );
};

export default DefaultLayout;
