import React from "react";
import { HStack, VStack } from "@chakra-ui/layout";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Logo } from "./icons/Logo";
import { FaBars } from "react-icons/fa";
import { ChangeColorModeButton } from "./ChangeColorModeButton";

const HeaderContent = () => (
  <>
    <Button variant="ghost">Home</Button>
    <Button variant="ghost">About</Button>
    <Button variant="ghost">Pricing</Button>
  </>
);

export const Header = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <Flex my="6" w="full" align="center" justify="space-between">
      <Logo />
      {isMobile ? (
        <>
          <IconButton
            onClick={onToggle}
            aria-label="menu"
            icon={<Icon as={FaBars} />}
          />
          <Drawer isOpen={isOpen} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader>Navigation</DrawerHeader>
              <DrawerBody h="full">
                <VStack w="full" align="stretch" spacing="2">
                  <HeaderContent />
                </VStack>
              </DrawerBody>
              <DrawerFooter justify="space-between">
                <VStack direction="column" w="full">
                  <Button w="full" color="white" bg="blue.500">
                    Log in
                  </Button>
                  <Button w="full">Sign up</Button>
                </VStack>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </>
      ) : (
        <HStack spacing="4">
          <HeaderContent />
          <ChangeColorModeButton />
          <Button color="white" bg="blue.500">
            Log in
          </Button>
          <Button>Sign up</Button>
        </HStack>
      )}
    </Flex>
  );
};
