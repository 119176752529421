import {
  Icon,
  IconButton,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaSun, FaMoon } from "react-icons/fa";
import React from "react";

export const ChangeColorModeButton = () => {
  const { toggleColorMode } = useColorMode();
  const icon = useColorModeValue(FaMoon, FaSun);

  return (
    <IconButton
      icon={<Icon as={icon} />}
      aria-label="change theme"
      onClick={toggleColorMode}
      bg="transparent"
    />
  );
};
