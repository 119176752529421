import { CodeProps, StackDivider } from "@chakra-ui/layout";
import { Box, Code, Icon, IconButton, useClipboard } from "@chakra-ui/react";
import { FaCopy, FaCheck } from "react-icons/fa";
import * as prism from "prismjs";
import React from "react";

type Props = CodeProps & {
  content: string;
  language?: string;
};

export const RichCode = ({ content, language, ...rest }: Props) => {
  const { hasCopied, onCopy } = useClipboard(content);
  const lang = language || "javascript";
  return (
    <Box
      bg="gray.800"
      w="full"
      borderRadius="lg"
      border="1px solid"
      borderColor="gray.600"
    >
      <Code
        {...rest}
        as="pre"
        color="white"
        bg="gray.800"
        p="4"
        whiteSpace="break-spaces"
        dangerouslySetInnerHTML={{
          __html: prism.highlight(content, prism.languages[lang], lang),
        }}
      ></Code>
      <StackDivider h="px" bg="gray.600" mx="2" />
      <Box mx="2" my="1">
        <IconButton
          onClick={onCopy}
          icon={<Icon as={hasCopied ? FaCheck : FaCopy} />}
          aria-label="copy to clipboard"
          bg="transparent"
          color="white"
        />
      </Box>
    </Box>
  );
};
