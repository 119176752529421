import React from "react";
import { Box, Button, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { Embed } from "./Embed";
import { RichCode } from "./RichCode";

export const IntroductionColumn = () => {
  const buttonValue = useColorModeValue("gray.300", "whiteAlpha.200");

  return (
    <Embed>
      <Text mt="4" casing="uppercase" fontWeight="bold" px="4">
        Receive Events
      </Text>
      <Box m="4">
        <VStack align="flex-start" w="full">
          <RichCode
            content={`const channel = natify.subscribe("tweets");

channel.on("newTweet", (tweet) => {
  console.log(tweet);
});`}
          />
        </VStack>
      </Box>
      <Text mt="4" casing="uppercase" fontWeight="bold" px="4">
        Send Events
      </Text>
      <Box m="4">
        <VStack align="flex-start" w="full">
          <RichCode
            content={`natify.broadcast("tweets", { 
  author: "@natify", 
  content: "This is the greatest show!" 
})`}
          />
        </VStack>
      </Box>
      <Button bg={buttonValue} mx="4" mb="4">
        Learn more
      </Button>
    </Embed>
  );
};
