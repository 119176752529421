import React from "react";
import { useColorModeValue } from "@chakra-ui/react";

export const Logo = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  const value = useColorModeValue("black", "white");

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="102.642"
      height="35.171"
      viewBox="0 0 102.642 35.171"
      fill={value}
      {...rest}
    >
      <path
        d="M2.522-27.173H6.091L21.841-5.316V-26.127h3.568V1.21H21.841L6.091-20.559V0H2.522ZM44.646-16.919h3.568V0H44.646V-3.363Q42.041.472,38.186.472a7.423,7.423,0,0,1-5.711-2.625,9.181,9.181,0,0,1-2.369-6.378,8.984,8.984,0,0,1,2.389-6.2A7.326,7.326,0,0,1,38.1-17.391,7.138,7.138,0,0,1,41.877-16.3a7.732,7.732,0,0,1,2.769,2.83Zm0,8.559c0-1.984-.063-2.916-1.172-4.016a5.459,5.459,0,0,0-3.986-1.651,5.474,5.474,0,0,0-3.945,1.641,5.211,5.211,0,0,0-1.685,3.855A5.622,5.622,0,0,0,35.5-4.45a5.366,5.366,0,0,0,3.986,1.682A5.426,5.426,0,0,0,43.474-4.44C44.583-5.554,44.646-6.376,44.646-8.36ZM54.715-21.841h3.568v4.922h3.527v3.261H58.283V0H54.715V-13.658h-2.81v-3.3h2.81ZM65.3-23.094h3.527v3.545H65.3Zm0,6.175h3.527V0H65.3ZM84.369-27.234v3.445H81.683a1.757,1.757,0,0,0-1.477.7,3.127,3.127,0,0,0-.533,1.942v4.233h4.7v3.261h-4.7V0H76.1V-13.658H72.987v-3.261H76.1V-20.91a6.8,6.8,0,0,1,1.487-4.605,4.983,4.983,0,0,1,3.968-1.719Zm2.687,10.315H91.1L97.24-7.728l4.243-9.191h3.681L93.735,7.937H90.009L95.6-4.166,90.68-11.507Z"
        transform="translate(-2.522 27.234)"
      />
    </svg>
  );
};
