import "../style/prism-theme.css";

import { GridItem, Heading, SimpleGrid, Text } from "@chakra-ui/layout";
import { Box, Button, Stack } from "@chakra-ui/react";
import * as React from "react";
import DefaultLayout from "../layouts";
import { IntroductionColumn } from "../components/IntroductionColumn";

const IndexPage = () => {
  return (
    <DefaultLayout>
      <SimpleGrid templateColumns={["1fr", null, "1fr 480px"]} my="12" gap="16">
        <GridItem mb="8">
          <Box>
            <Heading mb="8" lineHeight="lg">
              Turn your site into a dynamic web app in minutes
            </Heading>
            <Text mb="8" fontSize="xl" whiteSpace="break-spaces">
              Using Natify you can build and deploy scalable and
              production-ready websocket management in mere minutes. Focus on
              your business logic while we handle the rest.
            </Text>
          </Box>
          <Stack direction={["column", null, "row"]} mt="4">
            <Button color="white" bg="blue.500">
              Try for Free
            </Button>
            <Button>See it in action</Button>
          </Stack>
        </GridItem>
        <GridItem>
          <IntroductionColumn />
        </GridItem>
      </SimpleGrid>
    </DefaultLayout>
  );
};

export default IndexPage;
