import React from "react";
import {
  Box,
  Text,
  SimpleGrid,
  Input,
  VStack,
  Link,
  Button,
  Flex,
  HStack,
  Heading,
  useColorModeValue,
} from "@chakra-ui/react";
import { Logo } from "./icons/Logo";

export const Footer = () => {
  const borderColor = useColorModeValue("blackAlpha.300", "whiteAlpha.300");

  return (
    <>
      <Box w="full" borderTop="1px solid" borderColor={borderColor}>
        <Box my="8">
          <Heading as="h3" fontSize="2xl">
            Get Notified
          </Heading>
          <Text my="4">
            Receive an email everytime Natify releases a new product update.
          </Text>
          <Flex align="center">
            <HStack>
              <Input
                minW={["px", null, "300px"]}
                type="email"
                flex="1"
                placeholder="your@email.com"
              />
              <Button>Subscribe</Button>
            </HStack>
          </Flex>
        </Box>
      </Box>
      <Box my="8" w="full" borderTop="1px solid" borderColor={borderColor}>
        <SimpleGrid
          templateColumns={["1fr", null, "1fr 160px 160px"]}
          gap="8"
          my="8"
        >
          <Box>
            <Logo />
          </Box>
          <VStack align="flex-start" w="full">
            <Text fontWeight="bold">Product</Text>
            <Link>About</Link>
            <Link>Pricing</Link>
            <Link>Get Natify</Link>
          </VStack>
          <VStack align="flex-start" w="full">
            <Text fontWeight="bold">Developers</Text>
            <Link>Documentation</Link>
            <Link>Libraries</Link>
          </VStack>
        </SimpleGrid>
      </Box>
    </>
  );
};
